
window.addEventListener('load', function () {
  let flkty;
  let cellIndex = 0;
  
  const init = () => {
    const slider = document.getElementById('banner-carousel-flickity');
    if (!slider) return;
    
    flkty = new window.Flickity(slider, {
      accessibility: false, // stops screen jumping on mobile
      adaptiveHeight: false,
      cellAlign: "left",
      cellSelector: ".flickity-cell",
      contain: true,
      draggable: ">1",
      dragThreshold: 10,
      selectedAttraction: 0.03,
      friction: 0.3,
      freeScroll: false,
      initialIndex: cellIndex,
      percentPosition: true,
      resize: true,
      pageDots: false,
      setGallerySize: true,
      wrapAround: true,
      prevNextButtons: true,
      autoPlay: 6500,
      pauseAutoPlayOnHover: false
    });
    
    // stop page from scrolling when swiping flickity
    flkty.on('dragStart', () => (document.ontouchmove = e => e.preventDefault()));
    flkty.on('dragEnd', () => (document.ontouchmove = () => true));
    
    const progress = document.getElementById('banner-carousel-progress');
    // const start = progress.querySelector('.start');
    if (progress !== null)
      var bar = progress.querySelector('.bar-progress');
    // const end = progress.querySelector('.end');
    
    const cellsLength = flkty.cells.length
    // start.innerText = `0${cellIndex+1}`;
    if (bar !== undefined)
      bar.style.width = (cellIndex+1) / cellsLength * 100 + '%'
    // end.innerText = `/ 0${cellsLength}`;
    
    flkty.on("change", (index) => {
      const current = index + 1
      // start.innerText = '0' + current
      if (bar !== undefined)
        bar.style.width = ((current / cellsLength) * 100) + '%'
      cellIndex = index
    });
  }
  
  const destroy = () => {
    flkty.destroy()
  }
  
  init()
  
  // reinit on window resize
  let debounce = null
  window.addEventListener('resize', () => {
    destroy()
    
    clearTimeout(debounce);
    debounce = setTimeout(()=> {
      init()
    }, 400);
  })
})