
window.addEventListener('load', function () {
  const languageSelectors = document.querySelectorAll('.language-selector');
  //let pathnames = window.location.pathname.split('/'); // /en-gb/menu
  //const currentLanguage = pathnames[1];
  //window.SD_CurrentLanguage = currentLanguage;// note: should be set by the backend

  if (languageSelectors) {
    [...languageSelectors].forEach(function (selector) {
      // this should be set on the back end on the html of the select, not here

      selector.addEventListener('change', function (e) {
        window.location = e.currentTarget.value;
      })
    })

  }
})
