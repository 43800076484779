
window.addEventListener('load', function () {
  const sliders = document.querySelectorAll('.flickity.flickity-generic')

  if (sliders) {
    [...sliders].forEach((slider) => {
      const flkty = new window.Flickity(slider, {
        accessibility: false, // stops screen jumping on mobile
        adaptiveHeight: false,
        cellAlign: "left",
        cellSelector: ".flickity-cell",
        contain: true,
        draggable: ">1",
        dragThreshold: 3, //
        freeScroll: true,
        selectedAttraction: 0.03,
        friction: 0.3,
        initialIndex: 0,
        percentPosition: true,
        resize: true,
        pageDots: false,
        setGallerySize: true,
        wrapAround: false,
        prevNextButtons: true,
      });

      // stop page from scrolling when swiping flickity
      let scrollY;
      flkty.on('dragStart', () => {
        scrollY = window.scrollY;
      });
      flkty.on('dragMove', () => {
        window.scrollTo(0, scrollY)
      });

      window.onresize = function () { flkty.resize(); };
    })

  }


  // const cells = flkty.cells.length;
  // flkty.on("change", (index) => { });
})
