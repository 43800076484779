
window.addEventListener('load', function () {
  const modal = document.getElementById('modal-delivery-partner');

  if (modal) {
    const partners = modal.querySelector('.partners');
    const cta = modal.querySelector('.cta');

    if (partners) {
      partners.addEventListener('click', (e) => {
        e.preventDefault();
        const clickedPartner = e.target;
        const href = clickedPartner.href;
        
        if (!href) {
          return;
        }

        [...partners.children].forEach((partner) => {
          partner.classList.remove('is-selected')
        })

        clickedPartner.classList.add('is-selected')
        cta.href = href;
        cta.innerText = clickedPartner.getAttribute("data-cta");
        cta.classList.remove('inactive').add('active');
      })
    }
  }
})
